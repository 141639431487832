
import smoothscroll from "smoothscroll-polyfill";
smoothscroll.polyfill();

import * as React from "react";
import * as ReactDOM from "react-dom";
import { HashRouter as Router, Route } from "react-router-dom";

import "./app.scss";

import { Logo } from "./components/Logo";
import { WideMenu } from "./components/WideMenu";
import { StackMenu } from "./components/StackMenu";
import { Home } from "./pages/home";
import { Info } from "./pages/info";

import { menu } from "./fixtures/menu";

const App = () => (
  <Router>
    <div className="container">
      <div className="page">
        <div className="header">
          <div className="logo">
            <Logo />
          </div>

          <WideMenu menu={menu} />
          <StackMenu menu={menu} /> {/* Only visible on mobile */}
        </div>

        <Route path="/" exact component={Home} />
        <Route path="/info" component={Info} />

        <div className="block-background">
          <div className="block footer" id="contact">
            <div className="column text-column center-column">
              <div className="logo">
                <Logo />
              </div>
            </div>
            <div className="column text-column info">
              <p>
                <span>
                  <b>
                    Contact <br />
                  </b>
                </span>
                <span>
                  Kunnen wij iets voor u betekenen of wilt u de mogelijkheden
                  met ons doorspreken? Neem gerust contact met ons op via{" "}
                  <a
                    href={"mailto:info@ergotherapiefriesland.nl"}
                    className="email"
                  >
                    info@ergotherapiefriesland.nl
                  </a>{" "}
                  dan helpen wij u verder.
                  <br />
                  <br />
                  &copy; Ergotherapie Friesland 2025
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Router>
);

ReactDOM.render(<App />, document.getElementById("root"));
